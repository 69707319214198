.blog-about {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  margin: 0 140px 0 140px;
  /* margin-top: 80px; */
  padding-bottom: 40px;
  font-family: "Poppins", sans-serif;
  background: #f6f8f9;
}

.blog-card {
  margin: 10px;
  max-width: 826px;
}

.blog-title {
  margin-top: 30px;
  padding-bottom: 0px;
}

.blog-about .MuiPaper-elevation1 {
  box-shadow: 
    0px 3px 1px -1px rgb(0 0 0 / 20%),
    1px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.blog-content {
  margin: 20px 400px 0 400px;
}

.blog-back-button {
  font-size: 20px!important;
  margin-top: 20px!important;
}

.blog-post {
  text-align: justify;
}

.blog-p-online-test {
  font-weight: bold;
  font-size: 20px;
}

.blog-test-link {
  color: #4287f5;
  font-size: 24px;
}

.blog-link {
  color: #4287f5;
}

.MuiTypography-h3 {
  line-height: 1.017!important;
}

.blog-heading {
  max-width: 826px;
  margin-bottom: 15px;
  text-align: center;
}

.blog-h2 {
  font-weight: bold;
}

.blog-h3 {
  font-weight: bold;
}

ul.blog-list-icon {
  list-style-image: url('../../img/checkbox.svg');
}

@media (max-width: 1200px) {
  .blog-about {
    margin: 0;
  }
}

@media (max-width: 800px) {
  .blog-about {
    flex-direction: column;
    align-items: center;
  }
  .blog-content {
    margin: 20px 50px 0 50px;
  }
}

@media (max-width: 650px) {
  .blog-image {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 425px) {
  .blog-about {
    margin: 0;
    padding: 0 5px 0 5px;
    background-color: #f6f8f9;
  }
  .blog-content {
    margin: 20px;
  }
  .MuiTypography-h3 {
    font-size: 2.1rem!important;
  }
  .blog-title-3 {
    padding-bottom: 0px!important;
  }
  .blog-h2, .blog-h3 {
    text-align: left;
  }
  .blog-title-long {
    font-size: 28px;
  }
}

@media (max-width: 375px) {
  .blog-post {
    font-size: 15px;
  }
  .blog-content {
    margin: 10px;
  }
  .blog-card {
    margin: 5px;
  }
  .MuiTypography-h3 {
    font-size: 1.9rem!important;
  }
  .blog-title {
    margin-top: 19px;
  }
}

@media (max-width: 320px) {
  .blog-post {
    font-size: 14px;
  }
  .MuiTypography-h3 {
    font-size: 1.7rem!important;
  }
}
