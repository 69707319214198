.analysis-content {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  /* height: 20vh; */
  text-align: center;
  margin: 40px 300px 80px 300px;
  /* color: unset; */
  background-color: #f6f8f9;
  border-radius: 10px;
  padding: 30px;
}

.analysis-title {
  font-size: 30px;
  margin-bottom: 50px;
  font-weight: 400;
}

.analysis {
  width: 70%;
}

.loading-gif {
  width: 60%;
}

.analysis-list {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.analysis-list-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.analysis-item-disable {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.5;
}

.analysis-item-label {
  margin-left: 25px;
}

@media (max-width: 1024px) {
  .analysis-content {
    margin: 20px 100px 40px 100px;
  }
}

@media (max-width: 768px) {
  .analysis {
    width: 85%;
  }
  .analysis-title {
    font-size: 25px;
  }
}

@media (max-width: 425px) {
  .analysis {
    width: 100%;
  }
  .analysis-content {
    margin: 0px;
    border-radius: 0px;
  }
  .analysis-title {
    font-size: 20px;
    margin-bottom: 30px;
  }
  .loading-gif {
    width: 100%;
  }

  .analysis-content {
    padding-bottom: 70px;
  }
}