.CardElement {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.CardElement .CardNumberElement,
.CardElement .CardExpiryElement,
.CardElement .CardCvcElement {
  margin-bottom: 10px;
}

.user-information-form {
  display: flex;
  flex-direction: row;
}

.user-information-form-wrap {
  border: 1px solid #e6e6e6!important;
  border-radius: 5px;
  background-color: white;
  padding: 16px;
  margin-bottom: 20px;
}

.user-information-form label {
  text-align: left;
  font-size: 15px;
  color: rgb(48, 49, 61);
}

.user-information-input {
  margin: 0px 10px 0px 0px;
  background-color: white;
  border: 1px solid #e6e6e6!important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  border-radius: 5px;
  padding: 0.55rem;
  width: 100%;
  color: rgb(48, 49, 61);
}

/* .user-information-input .MuiInputBase-root .MuiInputBase-input {
  background-color: white;
  border: 1px solid #e6e6e6!important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
  border-radius: 5px;
} */

.user-form-name, .user-form-email {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.user-information-input:focus-within {
  outline: 0px;
  border-color: hsla(210, 96%, 45%, 50%)!important;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
}

.user-form-name {
  margin-right: 12px;
}

.user-icon-text {
  font-size: 0.875rem;
  font-weight: 600;
  color: rgb(5, 112, 222);
  margin-bottom: 16px
}

.user-form-section {
  text-align: start;
  display: flex;
  flex-direction: row;
}

.user-svg {
  margin-top: 2px;
  margin-right: 20px;
}

.payment-message {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .user-information-input {
    width: 100%;
  }
  .user-information-form {
    display: flex;
    flex-direction: column;
  }
  .user-form-name {
    margin-right: 0px;
  }
  .user-information-input-name {
    margin-bottom: 10px;
  }
  .get-result-button {
    width: 100%!important;
  }
}

@media (max-width: 425px) {
  .user-information-input {
    width: 100%;
  }
  .get-result-button {
    margin-bottom: 5px!important;
  }
}