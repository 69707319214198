.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 20vh; */
    text-align: center;
    margin: 40px 300px 80px 300px;
    /* color: unset; */
    background-color: #f6f8f9;
    border-radius:10px;
    padding: 30px;
}
.home-link {
    margin: auto;
}
.contact-title {
    margin-top: 50px;
    margin-bottom: 300px;
    font-size: 22px;
}

@media (max-width: 800px) {
    .contact {
        margin: 40px 100px 80px 100px;
    }
}

@media (max-width: 425px) {
    .contact {
        margin: 0px;
        border-radius: 0px;
    }
    .contact-title {
        font-size: 18px;
    }
}

@media (max-width: 320px) {
    .contact-title {
        font-size: 16px;
    }
}