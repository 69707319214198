.summary-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 20vh; */
    text-align: center;
    margin: 40px 300px 80px 300px;
    /* color: unset; */
    background-color: #f6f8f9;
    border-radius:10px;
    padding: 30px;
  }

.summary-fields {
    display: flex;
    flex-direction: column;
    /* justify-content: center */
    align-items: center;
    margin-top: 10px;
}

.summary-field {
    width: 100%;
    margin: 7px !important;
}

/* .get-result-button {
  color: unset;
} */

.get-result-button .MuiButton-label{
  color: #F49F1C;
  /* font-weight: 500; */
  letter-spacing: 1px;
  font-weight: 400;
}

.summary-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: bold;
}

.summry-text {
  font-size: 55px;
  margin-top: 20px;
  margin-bottom: 30px;
}

.summry-text-2 {
  font-size: 35px;
  margin: 0px 10px 0px 10px;
  font-weight: normal;
}

.summry-text-4 {
  font-size: 45px;
  margin-bottom: 20px;
  /* padding-bottom: 20px; */
  font-weight: 400;
}

.summry-text-5 {
  font-size: 25px;
  /* border-top: 1px solid #e6e6e6; */
  /* padding-bottom: 20px; */
  font-weight: 400;
  /* padding-top: 30px; */
  margin: 20px 60px;
}

.summry-text-3 {
  border-top: 1px solid #e6e6e6;
  margin-top: 50px;
  padding-top: 60px;
  font-size: 30px;
  margin: 10px 10px 10px 0;
  font-weight: normal;
  text-align: left;
}

.form-text {
  text-align: left;
}

.summary-form {
  margin-bottom: 10px;
}

.image-container {
  max-height: 450px;
}

.summary-progres {
  display: flex;
  margin-left: 10px;
}

.payment-section {
  display: flex;
  flex-direction: column;
  /* text-align: left;
  margin-left: 35%; */
  width: 60%;
  margin: auto;
  /* border-top: 1px solid #e6e6e6; */
  /* padding-top: 5px; */
  margin-top: 30px;
}

.payment-radio {
  margin: 30px 30px;
}

.payment-radio-1 .MuiFormControlLabel-label {
  font-size: 22px;
}

.summary-list-wrap {
  background-color: #030E4F;
  padding: 20px 10px 20px 10px;
  margin-bottom: 30px;
  border-radius: 5px;
}

.action-label-wrap {
  margin-bottom: 40px;
  margin-top: 10px;
}

ul.summary-list {
  list-style-image: url('../../img/prices-check.svg');
}

.summary-list li {
  text-align: left;
}

.summary-list li .summary-list-item, .summary-price-wrap .price {
  color: #F49F1C;
}

.summary-list-item-2 {
  color: #e9ebec;
  margin-right: 15px;
}

.discount-label {
  /* margin-top: 5px; */
  /* padding-bottom: 15px; */
  padding-left: 100px;
  padding-right: 100px;
  font-weight: 400;
  font-size: 20px;
  color: #e9ebec;
  margin-bottom: 0px;
  padding-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  border-bottom: 1px solid;
  font-size: 18px;
}

.discount-label-new {
  color: rgb(247, 188, 96);
  font-size: 18px;
  font-weight: 400;
  margin: 5px 10px;
}

.discount-label-time {
  color: #e9ebec;
  font-size: 14px;
  font-weight: 400;
  margin: 8px 10px;
}

.all-inclusive {
  color: #e9ebec;
  font-size: 20px;
  margin-bottom: 0px;
}

.all-inclusive-wrap {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.discount-section-2 {
  display: flex;
  flex-direction:row;
  justify-content: space-between;
}

.discount-section-1 {
  border-bottom: 1px solid #e9ebec;
  display: flex;
  flex-direction: row;
  padding-bottom: 10px;
}

.discount-svg {
  margin-right: 5px;
}

.action-label {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 22px;
  margin-bottom: 0px;
}

.summary-price-wrap {
  font-size: 55px;
  padding: 0px 10px;
}

.price-wrap {
  display: inline-table;
}

.price {
  font-size: 55px;
  /* padding-left: 10px; */
}

.price-point {
  font-size: 35px;
  color: #F49F1C;
}

.old-price-point {
  font-size: 25px;
  color: #c9cccf;
}

.summary-price-wrap .old-price {
  color: #b5b8ba;
  font-size: 35px;
  padding: 0px 5px;
}

.summary-list-item-wrap {
  margin-left: 5px;
}

.summary-list-item {
  font-weight: bold;
  margin-bottom: 0px;
}

.old-price {
  text-decoration: line-through;
}

/* .old-price-wrap {
  text-decoration: line-through;
  color: #c9cccf;
} */

.payment-form {
  /* border: 1px solid #030E4F; */
  border: 1px solid #e6e6e6;
  background-color: white;
  border-radius: 5px;
  padding: 15px 20px 25px 20px;
  margin-bottom: 20px;
}

.summary-cards {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 100px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.summary-card {
  background: #e8ebfa;
  flex: 1;
  margin: 10px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
}

.summary-card-text {
  font-weight: 280;
  font-size: 15px;
  margin-bottom: 0px;
}

.summary-description-sections, .summary-description-sections-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.trophy-wrap, .question-wrap, .customers-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #cdd5db;
  border-radius: 35px;
  margin: 30px 80px;
  padding: 40px;
  width: 70%;
}

.trophy-wrap-2 {
  margin-top: 5px;
}

.customers-wrap-2 {
  border-radius: 5px !important;
  margin: auto;
  background-color: #fdecd2;
}

.subtitle-wrap {
  border: 2px solid #f8c577;
  border-radius: 35px;
  padding: 25px 70px;
  margin-bottom: 30px;
  background-color: #fdecd2;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 80px;
  margin-right: 80px;
  width: 70%;
}

.subtitle-wrap-2 {
  border: none;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.trophy-text-wrap, .question-text-wrap {
  padding: 20px 50px 0px 50px;
}
.trophy-text, .question-text, .trophy-text-bold, .question-text-bold, .customers-title {
  font-size: 23px;
  font-weight: 400;
  margin-bottom: 0px;
}

.trophy-text-bold, .question-text-bold, .customers-title {
  font-weight: bold;
}

.trophy-text-bold, .question-text-bold {
  margin-bottom: 20px;
}

.customers-title {
  margin-bottom: 30px;
  padding-top: 20px;
}

.customers-review-wrap {
  display: flex;
  flex-direction: row;
}

.customers-review {
  flex: 1;
  margin: 0px 20px;
}

.customers-review-text {
  font-weight: 400;
  padding-bottom: 20px;
}

.customers-review-name, .customers-review-text {
  text-align: left;
}

.customers-review-name {
  font-weight: bold;
  margin-bottom: 0px;
}

.customers-review-text {
  font-size: 16px;
  margin-top: 15px;
}

.quote {
  font-size: 25px;
}

.special-offer {
  font-size: 45px;
  /* color: #c9cccf; */
}

.what-you-get {
  font-size: 40px;
  color: #e9ebec;
  font-weight: 500;
}

.payment-details {
  font-size: 30px;
  font-weight: 500;
}

.special-offer-image {
  margin-top: 10px;
}

.bold-text {
  font-weight: bold;
}

.customer-name {
  color: #F49F1C;
  text-transform: capitalize;
}

.summary-list-wrap-2 {
  margin-top: 30px;
}

.discount-image {
  margin-top: 10px;
  margin-bottom: 15px;
}

.ebook-title {
  color: #e9ebec;
  text-align: center;
}

.ebook-wrap {
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
  width: 100%;
  border-top: 1px solid #e9ebec;
  margin-top: 30px;
  padding-top: 15px;
}

.ebook-text {
  margin: 10px 20px;
}

.ebook-image {
  margin: 10px 30px;
}

.ebook-text {
  text-align: left;
}

.ebook-free {
  font-size: 23px;
  margin-bottom: 0px;
}

.ebook-name {
  color: #e9ebec;
}

.summary-cards-1 {
  width: 85%;
}

.new-desc {
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 17px;
}

.summary-cards-1 {
  margin-top: 10px;
  margin-bottom: 20px;
}

.summary-card-2 {
  border-radius: 5px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.customers-stars {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.why-to-pay-parent .MuiAccordionSummary-content {
  justify-content: center!important;
}

@media (max-width: 1300px) {
  .summry-text-5 {
    margin: 0px 0px;
  }
}

@media (max-width: 1024px) {
  .ebook-wrap {
    padding: 10px 10px 0px 10px; 
  }
  .ebook-text, .ebook-image {
    margin: 10px 10px;
  }
  .trophy-wrap, .question-wrap, .customers-wrap, .subtitle-wrap {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .summary-cards {
    flex-direction: column;
  }

  .summary-card {
    width: 100%;
  }
  .discount-label-new {
    font-size: 16px;
    margin: 5px;
  }
  .discount-label-time {
    font-size: 13px;
    margin: 8px 6px;
  }
  .discount-svg {
    
    margin-right: 0px;
  }
}

@media (max-width: 1440px) {
  .summary-content {
    margin: 40px 100px 80px 100px;
  }
  .payment-section {
    width: 50%;
  }
}

@media (max-width: 1024px) {
  .payment-section {
    width: 70%;
  }
  .summary-cards-1 {
    width: 100%;
  }
  .payment-section {
    width: 70%;
  }
}

@media (max-width: 800px) {
    .summary-content {
      margin: 0 20px 0 20px;
    }

    .summary-field {
        /* width: 80%; */
    }
    .payment-section {
      width: 60%;
    }
    .summary-card {
      margin: 10px 0px;
    }
    .payment-section {
      width: 70%;
    }
}

@media (max-width: 425px) {
  .summary-content {
    margin: 0;
    border-radius: 0px;
    padding: 15px 0px;
  }
  .summry-text-2 {
    font-size: 30px;
  }
  .image-summary {
    width: 100%;
  }
  .summry-text {
    font-size: 43px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .summry-text-4 {
    font-size: 23px;
    line-height: 1.3;
  }
  .summry-text-5 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.3;
  }
  .summry-text-52 {
    margin-bottom: 0px!important;
  }
  .summry-text-2 {
    font-size: 25px;
  }
  .summry-text-3 {
    font-size: 24px;
    margin: 0;
  }
  .payment-section {
    width: 100%;
    margin-top: 20px;
    padding-top: 0px;
  }
  .payment-radio-1 .MuiFormControlLabel-label {
    font-size: 20px;
  }
  .summary-cards {
    padding: 0px;
  }
  .summary-card {
    margin: 10px 0px;
  }

  .trophy-wrap, .question-wrap, .customers-wrap {
    border-radius: 35px;
    margin: 20px 0px;
    padding: 20px;
    width: 100%;
  }
  .trophy-wrap-2 {
    margin-bottom: 5px;
  }
  .customers-wrap-2 {
    border-radius: 0px !important;
    margin: 20px 0px;
    padding: 20px;
    width: 100%;
  }
  .trophy-text-wrap, .question-text-wrap {
    padding: 20px 10px 0px 10px;
  }
  .trophy-text, .question-text, .trophy-text-bold, .question-text-bold, .customers-title {
    font-size: 18px;
    line-height: 1.3;
  }
  .customers-review-wrap {
    flex-direction: column;
  }
  .customers-review {
    flex: 1 1;
    margin: 15px 20px;
  }
  .action-label {
    font-size: 19px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .customers-review-name {
    margin-bottom: 0px;
  }
  .customers-review-text {
    margin-bottom: 7px;
  }
  .summary-list-item-2 {
    line-height: 1.3;
  }
  .special-offer {
    margin-bottom: 10px;
  }
  .what-you-get {
    font-size: 33px;
  }
  .discount-label {
    padding-left: 30px;
    padding-right: 30px;
    line-height: 1.3;
    padding-bottom: 20px;
    border-bottom: 1px solid;
    font-size: 18px;
  }
  /* .summary-price-wrap {
    margin-bottom: 10px;
  } */
  .summary-cards {
    margin-top: 25px;
  }
  .trophy-text-bold, .question-text-bold, .customers-title {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .summary-list-wrap-2, .action-label-wrap, .payment-form, .summary-cards, .summary-description-sections, .summary-description-sections-2 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .summary-list-wrap-2 {
    margin-top: 20px;
    padding: 10px;
  }
  .summary-list-wrap-1 {
    border-radius: 0px ;
  }
  .subtitle-wrap {
    padding: 15px 20px;
    margin-bottom: 20px;
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
  .summary-card-2 {
    padding: 0px 75px;
  }
  .summary-card-21 {
    margin-bottom: 20px;
  }
  .new-des-img {
    margin-top: 20px;
  }
  .new-desc {
    margin-bottom: 18px;
  }
  .summary-cards-1 {
    margin-top: 10px;
  }
  .summary-cards-1 {
    margin-bottom: 15px;
  }
  .summary-cards {
    margin-bottom: 15px;
  }
  .ebook-free {
    font-size: 22px;
  }
  .summary-list-wrap-2 {
    margin-top: 20px;
  }
  .summary-list-wrap {
    margin-top: 20px;
  }
  .summary-list-wrap {
    margin-bottom: 20px;
  }
  .payment-section {
    margin-top: 0px;
  }

  .discount-section-1 {
    flex-direction: column;
    align-items: center;
  }

  .discount-label-new {
    font-size: 18px;
    margin: 5px 20px;
  }
  .discount-label-time {
    font-size: 15px;
    margin-top: 0px;
  }
  .all-inclusive {
    font-size: 19px;
  }
  .all-inclusive-es {
    font-size: 17px;
  }
  .action-label-wrap {
    margin-bottom: 30px;
  }
  .price {
    font-size: 45px;
  }
  .price-point {
    font-size: 25px;
    color: #F49F1C;
  }
  .summary-price-wrap .old-price {
    font-size: 25px;
    padding: 0px;
  }
  .payment-form {
    padding: 15px 15px 25px 15px;
  }
}

@media (max-width: 410px) {
  .subtitle-wrap {
    padding: 15px 15px;
  }
}

@media (max-width: 400px) {
  .summry-text {
    font-size: 40px;
  }
  .subtitle-wrap {
    padding: 15px 10px;
  }
  .summry-text-5 {
    font-size: 19px;
  }
  .all-inclusive-es {
    font-size: 15px;
  }
}

@media (max-width: 380px) {
  .summry-text-5 {
    font-size: 18px;
  }
}

@media (max-width: 375px) {
  .summry-text {
    font-size: 37px;
    margin: 20px 0px 16px 0px;
  }

  .summry-text-4 {
    font-size: 21px;
  }

  .summry-text-5 {
    font-size: 18px;
  }
  
  .summry-text-2 {
    font-size: 24px;
    /* margin: 15px; */
  }

  .summry-text-3 {
    font-size: 24px;
  }
  /* .summry-text-3 {
    font-size: 16px;
  } */
  .summary-field {
    width: 100%;
  }
  .summary-content {
    padding: 5px 0px 30px 0px;
  }
  .trophy-text, .question-text {
    font-size: 17px;
  }
  .trophy-text-bold, .question-text-bold, .customers-title {
    font-size: 20px;
  }
  .action-label {
    font-size: 19px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .summary-list-wrap-2, .action-label-wrap, .payment-form, .summary-cards, .summary-description-sections {
    margin-left: 15px;
    margin-right: 15px;
  }
  .summary-list-wrap-2 {
    padding-bottom: 5px;
  }
  .summary-description-sections-2 {
    margin-left: 15px;
    margin-right: 15px;
  }
  .discount-label {
    padding-left: 15px;
    padding-right: 15px;
  }
  .ebook-free {
    font-size: 21px;
  }
  .summary-card-2 {
    padding: 0px 55px;
  }
  .subtitle-wrap {
    padding: 15px 10px;
  }
  .what-you-get, .payment-details {
    font-size: 30px;
  }
  .action-label {
    font-size: 18px;
  }
  .all-inclusive {
    font-size: 18px;
  }
  .all-inclusive-es {
    font-size: 15px;
  }
  .old-price {
    margin-right: -5px;
  }
}
@media (max-width: 360px) {
  .ebook-free {
    font-size: 20px;
  }
  .summary-description-sections-2 {
    margin-left: 10px;
    margin-right: 10px;
  }
  .discount-label-new {
    font-size: 19px;
  }
  .discount-label-time {
    font-size: 14px;
  }
  .all-inclusive {
    font-size: 17px;
  }
  .all-inclusive-es {
    font-size: 15px;
  }
  .summary-price-wrap .old-price {
    font-size: 23px;
  }
  .price {
    font-size: 43px;
  }
  .price-point {
    font-size: 23px;
  }
}

@media (max-width: 350px) {
  .summary-description-sections-2 {
    margin-left: 7px;
    margin-right: 7px;
  }
  .summry-text-5, .new-desc {
    font-size: 17px;
  }
  .all-inclusive-es {
    font-size: 14px;
  }
}

@media (max-width: 343px) {
  .ebook-free {
    font-size: 18px;
  }
  .ebook-name {
    font-size: 14px;
  }
  .what-you-get, .payment-details {
    font-size: 27px;
  }
  .discount-label-new {
    font-size: 18px;
  }
  .discount-label-time {
    font-size: 14px;
  }
  .summary-price-wrap .old-price {
    font-size: 22px;
  }
  .price {
    font-size: 42px;
  }
  .price-point {
    font-size: 22px;
  }
  .all-inclusive {
    font-size: 16px;
  }
  .all-inclusive-es {
    font-size: 13px;
  }
}

@media (max-width: 325px) {
  .ebook-free {
    font-size: 17px;
  }
  .ebook-name {
    font-size: 13px;
  }
  .new-desc {
    font-size: 16px;
  }
}

@media (max-width: 320px) {
  .summry-text {
    font-size: 27px;
    margin: 15px 0px 10px 0px;
  }
  .summry-text-4 {
    font-size: 19px;
  }
  .summry-text-2 {
    font-size: 21px;
  }
  .summry-text-3 {
    font-size: 20px;
  }
  .summary-content {
    padding: 10px 0px 20px 0px;
  }
  .summry-text-3 {
    margin: 5px 5px 5px 0;
  }
  .summary-list-item {
    font-size: 15px;
  }
  .trophy-text, .question-text {
    font-size: 16px;
  }
  .trophy-text-bold, .question-text-bold, .customers-title {
    font-size: 18px;
  }
  .customers-review-text {
    font-size: 15px;
  }
  .summary-list-wrap-2, .action-label-wrap, .payment-form, .summary-cards, .summary-description-sections {
    margin-left: 10px;
    margin-right: 10px;
  }
  .summary-price-wrap .old-price {
    font-size: 22px;
  }
  .price {
    font-size: 42px;
  }
  .price-point {
    font-size: 22px;
  }
  .all-inclusive {
    font-size: 16px;
  }
  .all-inclusive-es {
    font-size: 13px;
  }
}