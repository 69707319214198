.termsOfUse {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 20vh; */
  text-align: center;
  margin: 40px 300px 80px 300px;
  /* color: unset; */
  background-color: #f6f8f9;
  border-radius:10px;
  padding: 30px;
}
.content-termsOfUse {
  text-align: left;
  margin: 0px 250px;
}
.home-link {
  margin: auto;
}
.termsOfUse-title {
  margin-top: 50px;
  margin-bottom: 300px;
  font-size: 22px;
}


@media (max-width: 1800px) {
  .content-termsOfUse {
    margin: 0px 100px;
  }
}

@media (max-width: 1400px) {
  .content-termsOfUse {
    margin: 0px;
  }
}

@media (max-width: 800px) {
  .termsOfUse {
      margin: 40px 100px 80px 100px;
  }
}

@media (max-width: 425px) {
  .termsOfUse {
      margin: 0px;
      border-radius: 0px;
  }
  .termsOfUse-title {
      font-size: 18px;
  }
}