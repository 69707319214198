.modal-style {
  top: 50%;
  left: 50%;
  width: 800px;
  border: 2px solid rgb(0, 0, 0);
  padding: 32px;
  position: absolute;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}

/* Timeline Container */
.timeline {
  background: var(--primary-color);
  margin: 20px auto;
  padding: 20px 20px 0px 20px;
}

/* Outer Layer with the timeline border */
.outer {
  border-left: 2px solid #333;
}

/* Card container */
.card {
  position: relative;
  margin: 0 0 20px 20px;
  padding: 10px 20px;
  /* background: rgb(251, 220, 173); */
  background: #e6e7ed;
  color: gray;
  border-radius: 8px;
  max-width: 600px;
}

.card-disable {
  opacity: 0.4;
}

/* Information about the timeline */
.info {
  display: flex;
  flex-direction: column;
}

/* Title of the card */
.title-timeline {
  position: relative;
  font-size: 23px;
}

/* Timeline dot  */
.title-timeline::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: white;
  border-radius: 999px;
  left: -49px;
  border: 3px solid orangered;
}

.question-question {
  font-size: 23px;
}
.question-question-english {
  font-size: 18px;
}
.number-of-question {
  font-size: 30px;
  font-weight: bold;
}

.timeline-description-1, .timeline-description-2 {
  font-size: 25px;
}

.timeline-description-3 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 0;
}

.info p {
  margin-bottom: 0;
}

.form-modal-proceed {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
}

@media (max-width: 800px) {
  .modal-style {
    width: 600px;
  }
}

@media (max-width: 425px) {
  .modal-style {
    width: 400px;
    padding: 15px;
  }

  .timeline-description-1, .timeline-description-2 {
    font-size: 20px;
  }

  .timeline-description-1 {
    margin-bottom: 0.5rem;
  }

  .timeline {
    margin-top: 0;
  }

  .title-timeline {
    font-size: 20px;
  }
  .number-of-question {
    font-size: 27px;
  }
  .start-test-wrap-modal .MuiButtonBase-root {
    margin: 0px 20px 20px 20px;
  }

  .card {
    padding: 5px 15px;
  }

  .title-timeline::before {
    left: -44px;
  }
  .question-question-english {
    font-size: 13px;
  }
}

@media (max-width: 410px) {
  .modal-style {
    width: 390px;
    padding: 13px;
  }
}

@media (max-width: 395px) {
  .modal-style {
    width: 380px;
    padding: 11px;
  }
}
@media (max-width: 385px) {
  .modal-style {
    width: 370px;
    padding: 9px;
  }
}

@media (max-width: 375px) {
  .modal-style {
    width: 360px;
    padding: 7px;
  }

  .timeline-description-1, .timeline-description-2 {
    font-size: 18px;
  }

  .timeline-description-1 {
    margin-bottom: 0.4rem;
  }

  .timeline {
    margin-top: 0;
  }

  .title-timeline {
    font-size: 18px;
  }
  .number-of-question {
    font-size: 24px;
  }
  .start-test-wrap-modal .MuiButtonBase-root {
    margin: 0px 20px 20px 20px;
    padding: 15px;
  }
}

@media (max-width: 360px) {
  .modal-style {
    width: 350px;
    padding: 6px;
  }
}
@media (max-width: 347px) {
  .modal-style {
    width: 340px;
    padding: 5px;
  }
  .question-question-english {
    font-size: 12px;
  }
}
@media (max-width: 335px) {
  .modal-style {
    width: 325px;
    padding: 4px;
  }
}

@media (max-width: 320px) {
  .modal-style {
    width: 310px;
    padding: 3px;
  }

  .timeline-description-1, .timeline-description-2 {
    font-size: 16px;
  }

  .timeline-description-3 {
    font-size: 22px;
  }

  .timeline-description-1 {
    margin-bottom: 0.2rem;
  }

  .timeline {
    margin-top: 0;
    margin-bottom: 5px;
    padding-bottom: 10px;
  }

  .title-timeline {
    font-size: 16px;
  }
  .number-of-question {
    font-size: 21px;
  }
  .start-test-wrap-modal .MuiButtonBase-root {
    margin: 0px 10px 20px 10px;
    padding: 13px;
  }
  .question-question-english {
    font-size: 11px;
  }
}