.footer {
    text-align: center;
    /* margin: 40px 0px 10px 0px; */
    background-color: #030E4F;
    min-height: 200px;
    color: #dfe3e6;
}
.footer-content {
    border-bottom: 1px solid #dfe3e6;
    margin: 0 140px 0 140px;
    display: flex;
    padding: 40px 0;
}
.company-address {
    margin: 0 140px 0 140px;
}
.footer-logo {
    width: 33.3%;
}
.terms {
    display: flex;
    width: 66.6%;
}
.terms-left, .terms-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.footer-content .footer-logo p {
    color: #dfe3e6;
}

.footer-content .terms a {
    color: #dfe3e6;
}

.rights p {
    color: #dfe3e6;
    padding-top: 25px;
    padding-bottom: 20px;
    margin-bottom: 0;
}

.company-name p {
    color: #dfe3e6;
    padding-top: 15px;
    padding-bottom: 5px;
    margin-bottom: 0;
    font-size: 18px;
}

.company-address p {
    color: #dfe3e6;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}

.company-address-wrap {
    justify-content: center;
    display: flex;
    margin-bottom: 10px;
}

.address-info {
    margin-bottom: 10px;
    padding: 0px 20px;
}

.company-address-link {
    color: #dfe3e6;
    margin-top: 15px;
    padding-bottom: 5px;
    margin-bottom: 0;
}

.company-address {
    border-bottom: 1px solid #dfe3e6;
    padding-bottom: 15px;
}

.footer-link {
    margin-bottom: 5px;
}

.company-name p, .address-info p, .company-address-link {
    color: #9a9fb9;
}

@media (max-width: 800px) {
    .footer-content {
      flex-direction: column;
      /* align-items: center; */
      margin: 0 20px 0 20px;
    }
    .company-address {
        margin: 0 20px 0 20px;
    }
    .footer-logo {
        width: 60%;
        margin: auto;
        max-width: 231px;
    }
    .logo-image-footer{
        width: 100%;
    }
    .terms {
        /* text-align: start;
        margin-left: 15px; */
        width: 100%;
    }
    .rights, .company-address-wrap {
        font-size: 15px;
    }
}

@media (max-width: 425px) {
    .footer-logo {
        padding-bottom: 10px;
    }

    .footer-content {
        padding: 35px 0;
    }
    .rights, .company-address-wrap {
        font-size: 14px;
    }
    .footer-link {
        margin-bottom: 3px;
    }
    .terms-left, .terms-right {
        justify-content: start;
    }
    .company-address-wrap {
        flex-direction: column;
    }
}

@media (max-width: 375px) {
    .footer-logo {
        width: 70%;
    }
    .rights, .company-address-wrap {
        font-size: 12px;
    }
    .footer-link {
        margin-bottom: 2px;
    }
}

