.banner-about{
    width: 35%;
    align-self: center;
    padding: 8px;
    margin-top: 200px;
}
.settings-about{
    width: 60%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
}
.content-about {
    display: flex;
    justify-content: space-around;
    margin: 0 140px 0 140px;
    /* margin-top: 80px; */
    padding-bottom: 40px;
  }
.settings-about-2{
    width: 60%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    /* align-items: start; */
    align-items: flex-start;
    font-family: "Poppins", sans-serif;
    font-weight: 300;
}
.home-paragraph-about {
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 80px;
}
.features-list-2 {
  margin-top: 20px;
}

.about-goals {
  font-size: 50px;
  /* font-weight: bold; */
}

@media (max-width: 800px) {
    .content-about {
      flex-direction: column;
      align-items: center;
      margin: 0 20px 0 20px;
    }
    .banner-about {
      width: 85%;
      padding: 0;
      padding-top: 20px;
    }
    .settings-about{
      width: 100%;
    }
}

@media (max-width: 425px) {
  .about-title-en {
    font-size: 1.9em!important;
  }
  .settings-about {
    margin-left: 0;
  }
  .content-about {
    margin: 0;
    padding: 0 5px 0 5px;
    background-color: #f6f8f9;
  }
  .settings-about {
    padding: 0;
    margin-left: 0;
  }
  .home-paragraph-about {
    margin-top: 15px;
  }
  .banner-about {
    margin-top: 0;
    padding-top: 0;
  }
  .about-title-3 {
    padding-bottom: 5px;
  }
  .settings-about-2 {
    width: 100%;
  }
  .home-ready-2 {
    text-align: center;
  }
  .settings-about-2 {
    align-items: center;
  }
  .about-goals {
    font-size: 37px;
  }
  .settings-about-2 {
    margin-top: 70px;
    margin-bottom: 20px;
  }
  .about-text {
    margin-left: 15px;
  }
  .features-list-2 {
    margin-top: 5px;
  }
  .home-title-2 {
    margin-bottom: 15px;
  }
}

@media (max-width: 395px) {
  .about-title-en {
    font-size: 1.8em!important;
  }
}

@media (max-width: 375px) {
  .about-text {
    font-size: 16px;
  }

  .about-sublist-1, .list-group, .about-paragraph-3, .about-paragraph-2, .list-group-item, .about-title-3 {
    font-size: 14px;
  }

  .features-list-2 {
    font-size: 15px;
  }
}

@media (max-width: 375px) {
  .about-title-en {
    font-size: 1.7em!important;
  }
}

@media (max-width: 348px) {
  .about-title-en {
    font-size: 1.6em!important;
  }
}

@media (max-width: 335px) {
  .about-title-en {
    font-size: 1.5em!important;
  }
}

@media (max-width: 320px) {
  .about-text {
    font-size: 15px;
  }

  .about-sublist-1, .list-group, .about-paragraph-3, .about-paragraph-2, .list-group-item, .about-title-3 {
    font-size: 13px;
  }

  .features-list-2 {
    font-size: 14px;
  }

  .about-title-3 {
    padding-bottom: 7px;
  }

  .about-text {
    margin-left: 10px;
  }
  .features-list-2 {
    padding-left: 1rem;
  }
  .about-goals {
    font-size: 32px;
  }
}