.result-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 60vh; */
  text-align: center;
  margin: 40px 350px;
}

.result-container {
  /* border: 1px solid rgba(0, 0, 0, 0.12); */
  border: 2px solid gray;
  border-radius: 4px;
}

.result-description {
  /* padding-top: 7px; */
  background-color:#f6f8f9; 
  width:100%;
  border-radius: 5px;
}

.result-description-last {
  padding-bottom: 10px;
}

.result-description-main {
    /* margin-top: 50px; */
    /* padding-top: 10px; */
    background-color:#030E4F; 
    width:100%;
    border-radius: 5px;
}

.result-description-white {
  /* margin-top: 50px; */
  /* padding-top: 10px; */
  background-color: white; 
  width:100%;
}

.result-subdescription{
  padding: 30px 180px;
  font-size: 18px;
}

.description-title{
  margin-bottom: 20px;
}

.result-text {
  margin-bottom: 0px;
  text-align: justify;
}

.result-text-details {
  text-decoration: underline;
  text-decoration-color:#F49F1C;
  font-size: 28px;
}

.result-thanks {
  margin-top: 60px;
}

.result-button-wrap {
  margin-top: 10px;
}

.result-title{
  padding: 30px 180px;
  font-weight: bold;
  font-size: 38px;
  border-top: 1px solid gray;
  /* border-bottom: 1px solid gray; */
}

.result-main-title {
  margin-top: 80px;
  margin-bottom: 50px;
  font-size: 55px;
  /* text-decoration: underline;
  text-decoration-color:#F49F1C; */
}

.result-main-title-2 {
  margin: 20px 150px 60px 150px;
  font-size: 25px;
  font-weight: normal;
}

.home-link {
  margin: auto;
}

.result-text-li {
  text-align: left;
  margin: 10px;
}

.details-score {
  display: flex;
}

.details-score-logical, .details-score-personality {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.details-score-logical {
  margin-left: 30px;
}
.details-score-personality {
  margin-right: 30px;
}

.result-image-wrap {
  width: 40%;
  margin: auto;
}

.result-report {
  text-align: center;
}

.result-subdescription-report {
  padding-top: 0px;
}

.test-name {
  font-size: 30px;
  margin-top: 20px;
  margin-bottom: 0.5rem;
}

.result-title-2 {
  color: #F49F1C;
}

.result-title-1 {
  color: #dfe3e6;
}

.test-name-result {
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 25px;

}

.score-scale {
  margin-bottom: -20px;
}

.result-social {
  background-color: #f6f8f9;
  padding: 50px;
}
.social-title {
  text-align: center;
}
.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icon {
  border-radius: 10px;
  margin: 10px;
  background: white;
}

.learning-resources {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: justify;
}

.resource-link-wrap {
  display: flex;
  justify-content: center;
}

.resource-link {
  color: blue;
  margin-left: 10px;
}

.learning-resources-title {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.courseImage {
  margin-bottom: 20px;
}

.course-description {
  text-align: center;
}

.course-discount {
  margin: 20px 0 20px 0;
}

@media (max-width: 1700px) {
  .courseImage {
    width: 550px;
  }
}

@media (max-width: 1500px) {
  .result-content {
    margin: 0 200px 0 200px;
  }
  .courseImage {
    width: 500px;
  }
}

@media (max-width: 1250px) {
  .result-content {
    margin: 0 150px 0 150px;
  }
  .courseImage {
    width: 350px;
  }
}

@media (max-width: 950px) {
  .result-content {
    margin: 0 70px 0 70px;
  }
}

@media (max-width: 800px) {
  .result-content {
    margin: 0 20px 0 20px;
  }
}

@media (max-width: 425px) {
  .result-content {
    margin: 20px 10px 40px 10px;
  }
  /* .result-container {
    border: 1px solid gray;
  } */
  .result-description {
    margin-top: 0px;
  }
  .result-main-title {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 32px;
  }
  .result-main-title-2 {
    margin: 20px 10px 30px 10px;
    font-size: 20px;
  }
  .result-title {
    padding: 30px 10px;
    font-size: 28px;
  }
  .result-subdescription {
    padding: 5px 15px;
    font-size: 16px;
  }
  .result-thanks {
    font-size: 14px;
  }
  .details-score-logical {
    margin-left: 0px;
    border-right: 1px solid #e6e6e6;
  }
  .details-score-personality {
    border-left: 1px solid #e6e6e6;
    margin-right: 0px;
  }
  .score-scale {
    transform: scale(0.7);
    margin-top: -50px;
    margin-bottom: -50px;
  }
  .result-image-wrap {
    width: 70%;
  }
  .test-name {
    font-size: 20px;
    margin-top: 10px;
  }
  .test-name-result {
    font-size: 21px;
  }
  .result-social {
    padding: 20px;
  }
  .social-title {
    font-size: 21px;
  }
  .social-icon {
    width: 45px;
    height: 45px;
  }
  .courseImage {
    width: 300px;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .resource-link {
    margin-bottom: 10px;
  }
}

@media (max-width: 375px) {
  .result-main-title {
    font-size: 28px;
  }
  .result-main-title-2 {
    font-size: 18px;
  }
  .result-title {
    font-size: 23px;
  }
  .result-subdescription {
    font-size: 15px;
  }
  .result-thanks {
    font-size: 13px;
  }
  .test-name, .test-name-result {
    font-size: 18px;
  }
  .result-image-wrap {
    width: 75%;
  }
  .score-scale {
    margin-bottom: -60px;
  }
  .social-title {
    font-size: 20px;
  }
  .courseImage {
    width: 250px;
  }
}

@media (max-width: 320px) {
  .result-main-title {
    font-size: 23px;
  }
  .result-main-title-2 {
    font-size: 15px;
  }
  .result-title {
    font-size: 18px;
  }
  .result-subdescription {
    font-size: 14px;
  }
  .score-scale {
    transform: scale(0.6);
  }
  .test-name, .test-name-result {
    font-size: 17px;
  }
  .social-title {
    font-size: 17px;
  }
}