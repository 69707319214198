.content {
  display: flex;
  justify-content: space-around;
  margin: 40px 140px 70px 140px;
}

.content-2, .content-3, .content-3-1{
  display: flex;
  justify-content: space-around;
  margin: 0 140px 0 140px;
  padding-top: 80px;
  padding-bottom: 60px;
}
.content-2-new, .content-2-1 {
  display: flex;
  justify-content: space-around;
  margin: 0 140px 0 140px;
  padding-top: 80px;
  padding-bottom: 60px;
  flex-direction: column;
  align-items: center;
}

.content-2, .content-2-1 {
  margin-top: 40px;
}

.home-section-2-1 {
  background: #686e95;
}

.home-section-3 {
  background-color: #f6f8f9;
}

.result-social-home {
  background: #e8ebfa;
  padding: 50px;
}

.section-3-people {
  min-height: 650px;
  display: flex;
  /* flex-direction: column;
  align-items: center; */
}
.section-3-people-1 {
  background-image: url('../../img/section-31-people.png');
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: 100% 50% !important;
}

.home-trusted {
  font-size: 22px;
  margin-bottom: 0px;
  text-align: center;
  color: #F49F1C;
}
.content-2-new {
  margin-top: 0px;
}

.home-why-section {
  display: flex;
}

.home-why-card {
  width: 30%;
  margin: auto;
  padding: 10px;
  background-color: #f6f8f9!important;
  box-shadow: none!important;
}

.why-card-title {
  text-align: center;
  font-size: 25px;
  margin-bottom: 30px;
  font-weight: bold;
}

.why-card-description {
  text-align: center;
}

.why-image {
  width: 180px;
  margin: auto;
}

.settings {
  width: 55%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
.settings-3, .settings-3-1 {
  width: 50%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.banner {
  width: 45%;
  align-self: center;
  padding: 8px;
  padding-top: 130px;
}
.banner-3 {
  width: 30%;
  align-self: center;
  padding: 60px;
}

.banner-3-1 {
  width: 50%;
  align-self: center;
  padding: 30px;
}

.banner-image {
  width: 100%;
  /* border-bottom: 2px solid #F49F1C */
}

.settings__select {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  width: 100%;
  flex: 0.8;
  justify-content: space-evenly;
}

.home-title-en, .home-title-sr, .home-title-es, .home-title-de {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 50px;
  padding-bottom: 25px;
  line-height: 1.2em;
}

.home-title-section-2 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 50px;
  padding-bottom: 35px;
  line-height: 1.4em;
  margin-top: 20px;
  /* text-align: center; */
}

.home-title-section-3-1 {
  font-weight: bold;
  font-size: 45px;
  padding-bottom: 35px;
  line-height: 1.4em;
  margin-top: 50px;
}
.home-p-section-3-1 {
  font-size: 22px;
  padding-bottom: 35px;
  line-height: 1.4em;
  margin-top: 50px;
}

.home-title-section-3 {
  margin-left: 50px;
}

.home-ready-2 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 35px;
  line-height: 1.4em;
  margin-top: 40px;
  /* text-align: center; */
}

.home-title-2 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 26px;
  padding: 5px 0 5px 0;
}

.home-title-3 {
  /* text-transform: uppercase; */
  /* font-weight: bold; */
  font-size: 20px;
  padding: 5px 0 5px 0;
}

.title-coding {
  color: #F49F1C;
  display: block;
}
.title-coding-why {
  color: #F49F1C;
}
.title-coding-about {
  /* text-decoration: underline;
  text-decoration-color:#F49F1C; */
  color: #F49F1C;
}

.home-paragraph {
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 30px;
}

.home-paragraph-2, .home-paragraph-3 {
  /* font-weight: bold; */
  margin-bottom: 10px;
  margin-top: 30px;
  display: none;
}

.home-paragraph-3 span {
  display: block;
}

.home-sublist-language span {
  display: block;
}

.home-section-2 {
  background:#f6f8f9;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.home-section-5 {
  background:#c1c4d4;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}

.home-section-2-1 {
  /* background:#f6f8f9; */
  width: 100%;
}

.settings-3-text {
  margin-bottom: 80px;
}

.settings-3-1-text {
  padding: 0px 50px;
}

.list-task-group {
  font-weight: bold;
  text-transform: uppercase;
}

.list-3 {
  color: #030E4F;
  margin-top: 5px;
  margin-bottom: 5px;
}

.list-group-item-light {
    background-color: #e8ebfa!important;
    color: #030E4F!important;
}

.list-group-item {
  /* padding-bottom: 15px!important;
  padding-top: 15px!important; */
  padding: 40px!important;
  text-align:justify;
}

.list-task-group, .home-title-3 {
  margin-bottom: 0;
}

.home-list-section-1 {
  margin-bottom: 10px;
}

.list-group-item {
  border-radius: 8px;
}

ul.features-list {
  margin-top: 30px;
  margin-bottom: 30px;
  list-style-image: url('../../img/checkbox.svg');
}

ul.home-sublist {
  list-style-image:none;
}

.home-sublist-1 {
  margin-bottom: 0px;
}

.ban-banner {
  width: 45%;
}

.ban-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ban-container {
  background-color: white;
  width: 70%;
  border-radius: 10px;
  padding: 40px;
}

.ban-container-head {
  display: flex;
}

.ban-image {
  border-radius: 50%;
}

.ban-head-desc {
  font-size: 14px;
  margin-bottom: 3px;
  margin-left: 20px;
}

.ban-head-desc-name {
  margin-bottom: 5px;
  font-weight: 700;
}

.ban-content {
  font-weight: 500;
  margin-top: 30px;
  font-size: 22px;
}

.ban-button {
  float: right;
  margin-top: 20px!important;
}

.what-test-paragraph {
  /* font-style: italic; */
  font-size: 19px;
}

.content-2-new .MuiAccordion-rounded {
  border-radius: 8px!important;
  position: inherit!important;
  margin: 5px;
  background-color: #f6f8f9;
  width: 60%;
}

.content-2-new .MuiAccordion-rounded .MuiButtonBase-root {
  padding: 5px 20px;
}

.MuiAccordionSummary-content p {
  font-size: 20px;
}

.faq-title {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.00938em;
  margin-bottom: 0px;
}

.home-section-5-title {
  font-weight: bold;
  margin-bottom: 30px;
}

/* help color #008080 */

@media (max-width: 1740px) {
  .home-title-es, .home-title-de {
    font-size: 42px;
  }
}

@media (max-width: 1575px) {
  .home-title-en, .home-title-sr {
    font-size: 45px;
  }
}

@media (max-width: 1490px) {
  .home-title-es, .home-title-de {
    font-size: 40px;
  }
}

@media (max-width: 1455px) {
  .home-title-en, .home-title-sr {
    font-size: 42px;
  }
  .ban-banner {
    width: 47%;
  }
}

@media (max-width: 1440px) {
  .home-title-es, .home-title-de {
    font-size: 38px;
  }
  ul.features-list {
    margin-bottom: 10px;
  }
}

@media (max-width: 1390px) {
  .home-title-es, .home-title-de {
    font-size: 36px;
  }
}

@media (max-width: 1370px) {
  .home-title-en, .home-title-sr {
    font-size: 40px;
  }
  .content {
    margin-top: 20px;
  }
  .home-title-3 {
    font-size: 16px;
  }
  .home-title-2 {
    font-size: 24px;
    padding: 0px;
  }
  .home-paragraph-3 {
    font-size: 15px;
  }
}

@media (max-width: 1330px) {
  .home-title-es, .home-title-de {
    font-size: 34px;
  }
  .home-title-en, .home-title-sr, .home-title-es, .home-title-de {
    padding-bottom: 15px;
  }
}


@media (max-width: 1200px) {
  .content {
    margin: 20px 40px 10px 40px;
  }
  .content-2-new {
    margin: 0px 40px 0px 40px;
  }
  .content-3, .content-3-1 {
    margin: 0px 60px 0px 60px;
  }
  .banner {
    width: 85%;
    padding: 0;
    padding-top: 20px;
  }
  .settings {
    width: 100%;
  }
  .home-title-en {
    font-size: 39px;
  }
}
@media (max-width: 1024px) {
  .home-title-en {
    font-size: 35px;
  }
  .home-trusted {
    font-size: 18px;
  }
  .ban-banner {
    width: 65%;
  }
  .home-paragraph-3 {
    display: none;
  }
  .settings-3 {
    width: 80%;
  }
  .banner-3 {
    padding: 10px;
  }
  .home-title-es, .home-title-de {
    font-size: 31px;
  }
  .home-title-2 {
    font-size: 20px;
  }
  .home-title-section-3-1 {
    padding-bottom: 1rem;
    font-size: 29px;
  }
  .home-p-section-3-1 {
    padding-bottom: 1rem;
    font-size: 20px;
  }
  .settings-3-1-text {
    padding: 0px 20px;
  }
  .banner-3-1 {
    padding: 20px;
  }
  .settings-3-1 {
    padding: 20px;
  }
  .home-title-section-3-1 {
    margin-top: 0px;
  }
}

@media (max-width: 800px) {
  .content, .content-3 {
    flex-direction: column;
    align-items: center;
    margin: 0 20px 0 20px;
  }
  .content-3-1 {
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 20px 0 20px;
  }
  .content-2 {
    flex-direction: column-reverse;
    align-items: center;
    margin: 0 20px 0 20px;
  }
  .content-2-new {
    margin: 0 20px 0 20px;
  }
  .home-why-section {
    flex-direction: column;
  }
  .home-why-card {
    width: 90%;
    background-color: #eff1f2!important;
  }
  .why-card-title {
    margin-bottom: 20px;
  }
  .banner {
    width: 85%;
    padding: 0;
    padding-top: 20px;
  }
  .banner-3 {
    width: 60%;
    padding: 0;
    padding-top: 20px;
  }
  .settings, .settings-3 {
    width: 100%;
  }
  .banner-3 {
    width: 27%;
  }
  .banner-3-1 {
    width: 80%;
  }
  .settings-3-1 {
    width: 100%;
  }
  .home-title-section-3-1 {
    font-size: 35px;
  }
  .home-p-section-3-1 {
    font-size: 20px;
  }
  .home-p-section-3-1 {
    margin: 0px;
  }
}

@media (max-width: 768px) {
  .home-title-en {
    font-size: 38px;
  }

  .home-title-sr, .home-title-es, .home-title-de {
    font-size: 37px;
  }
  .settings-3 {
    padding: 0 10px;
  }
  .content-2-1 {
    margin: 0 70px 0 70px;
  }
  .ban-banner {
    width: 100%;
  }
}

@media (max-width: 550px) {
  .home-title-en {
    font-size: 33px;
  }

  .home-title-sr, .home-title-es, .home-title-de {
    font-size: 34px;
  }
}

@media (max-width: 425px) {
  .home-title-en {
    margin-top: 15px;
    line-height: 1.2em;
    padding-bottom: 0px;
    text-align: center;
    font-size: 33px;
    margin-bottom: 15px;
  }

  .home-title-sr, .home-title-es, .home-title-de {
    margin-top: 15px;
    line-height: 1.2em;
    padding-bottom: 0px;
    text-align: center;
    font-size: 33px;
    margin-bottom: 10px;
  }

  .home-title-2 {
    font-size: 18px;
    margin-left: 15px;
    margin-bottom: 2px;
    margin-top: 3px;
  }

  .home-title-3 {
    font-size: 15px;
    padding: 5px 0 15px 0;
    margin-left: 15px;
  }

  .content {
    margin: 0;
    padding: 0 5px 0 5px;
    background-color: #f6f8f9;
  }

  .content-2, .content-2-new {
    margin: 0;
    padding: 0 5px 0 5px;
  }

  .settings {
    padding: 5px;
  }
  .banner {
    width: 92%;
  }
  .home-paragraph-2 {
    margin-top: 0px;
    font-size: 15px;
    margin-left: 15px;
  }
  .home-paragraph-3 {
    display: none;
    margin-top: 10px;
    font-size: 15px;
    margin-left: 15px;
  }
  .title-coding {
    display: inline;
  }
  .banner {
    padding-top: 45px;
  }
  .home-paragraph-3 span {
    display: inline;
  }
  .home-sublist-language span {
    display: inline;
  }
  .home-sublist-1 {
    font-size: 15px;
  }
  .home-paragraph-3 {
    background-color: #f6f8f9;
    padding: 5px;
  }
  .settings__select {
    padding-left: 0;
  }
  .start-test-wrap {
    display: flex;
    justify-content: center;
  }
  .list-group {
    font-size: 15px;
    text-align: left;
  }
  .home-section-2 {
    background-color: #eff1f2;
  }
  .settings-3 {
    margin-left: 0;
    padding: 0 10px;
  }
  .features-list {
    padding-left: 2.6rem;
  }
  .list-group-item {
    padding: 20px 30px!important;
    /* padding: 15px; */
    prefix: "»"!important;
  }
  .home-title-section-2 {
    padding-bottom: 1rem;
    padding-top: 3rem;
    font-size: 35px;
    text-align: center;
  }

  .home-title-section-3-1 {
    padding-bottom: 1rem;
    padding-top: 3rem;
    font-size: 28px;
    text-align: center;
  }

  .home-p-section-3-1 {
    padding-bottom: 1rem;
    padding-top: 3rem;
    font-size: 16px;
    text-align: center;
  }
  
  .home-title-section-3 {
    margin-left: 0;
    text-align: center;
  }
  .content-3, .content-3-1 {
    padding-top: 0;
    padding-bottom: 30px;
  }
  .content-2, .content-2-new, .content-3, .content-3-1 {
    margin: 0;
    padding: 0 5px 0 5px;
  }
  .settings-3-text {
    margin-bottom: 20px;
  }

  .banner-3 {
    padding-top: 0;
  }
  .start-test-wrap button {
    margin-top: 10px;
  }
  .home-list-section-3 {
    box-shadow: 0px 0px!important;
  }
  .list-task-group {
    font-size: 17px;
  }
  .why-image {
    width: 150px;
  }

  .home-why-card {
    padding: 3px 10px 10px 10px;
  }
  .content-2-1 {
    margin: 0px 3px;
  }
  /* .home-section-2-1 {
    background: linear-gradient(180deg, #f2f5ff, #f6f8f9);
  } */
  .content-2-1 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .ban-banner {
    width: 100%;
  }
  .ban-container {
    width: 83%;
    padding: 27px;
  }
  .section-3-people {
    min-height: 650px;
  }
  .banner-3 {
    width: 45%;
  }
  ul.features-list {
    margin-top: 10px;
  }
  .home-p-section-3-1 {
    padding: 0px;
  }
  .banner-3-1 {
    width: 100%;
  }
  .result-social-home {
    padding: 40px 20px;
  }
  .settings-3-1-text {
    padding: 0px;
  }
  .what-test-paragraph {
    font-size: 16px;
  }
  .content-2-new .MuiAccordion-rounded {
    margin: 5px 10px;
  }
  .content-5 {
    padding: 40px 15px;
  }
  .content-2-new .MuiAccordion-rounded {
    width: 100%;
  }
}


@media (max-width: 410px) {
  .home-title-sr, .home-title-es, .home-title-de {
    font-size: 32px;
  }
  .home-title-en {
    font-size: 32px;
  }
  .section-3-people {
    min-height: 630px;
  }
  .home-title-section-3-1 {
    font-size: 27px;
  }
  /* .home-p-section-3-1 {
    font-size: 16px;
  } */
}

@media (max-width: 395px) {
  .home-title-sr, .home-title-es, .home-title-de {
    font-size: 31px;
  }
  .section-3-people {
    min-height: 615px;
  }
  .home-title-section-3-1 {
    font-size: 26px;
  }
}
@media (max-width: 385px) {
  .home-title-sr, .home-title-es, .home-title-de {
    font-size: 30px;
  }
  .home-title-2 {
    font-size: 16px;
  }
  .home-trusted {
    font-size: 17px;
  }
  .section-3-people {
    min-height: 585px;
  }
  .home-title-section-3-1 {
    font-size: 25px;
  }
}

@media (max-width: 375px) {
  .settings__select {
    padding-left: 0px;
  }
  .home-title-en {
    font-size: 31px;
  }

  .home-title-sr, .home-title-es, .home-title-de {
    font-size: 29px;
  }

  .home-sublist-1, .list-group, .home-paragraph-3, .home-paragraph-2, .list-group-item, .home-title-3 {
    font-size: 14px;
  }
  .list-task-group {
    font-size: 16px;
  }
  .home-why-card {
    width: 100%;
  }
  .content-2-1 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .section-3-people {
    min-height: 575px;
  }
  .ban-content {
    font-size: 20px;
  }
  .home-title-section-3-1 {
    font-size: 24px;
  }
  .list-group-item {
    padding: 20px 25px!important;
  }
}

@media (max-width: 360px) {
  .home-title-sr, .home-title-es, .home-title-de {
    font-size: 28px;
  }
  .home-title-2, .home-title-3, .home-paragraph-2, .home-paragraph-3 {
    margin-left: 10px;
  }
  .home-trusted {
    font-size: 16px;
  }
  .home-title-section-3-1 {
    font-size: 23px;
  }
}
@media (max-width: 347px) {
  .home-title-sr, .home-title-es, .home-title-de {
    font-size: 27px;
  }
  .home-title-en {
    font-size: 29px;
  }
  .section-3-people {
    min-height: 541px;
  }
  .home-title-section-3-1{
    font-size: 22px;
  }
  .home-section-5-title {
    font-size: 22px;
  }
}
@media (max-width: 335px) {
  .home-title-sr, .home-title-es, .home-title-de {
    font-size: 26px;
  }
  .home-title-en {
    font-size: 28px;
  }
  .home-title-2 {
    font-size: 15px;
  }
  .home-trusted {
    font-size: 15px;
  }
  .section-3-people {
    min-height: 523px;
  }
  .ban-content {
    font-size: 18px;
  }
  .home-title-section-3-1 {
    font-size: 21px;
  }
}

@media (max-width: 320px) {
  .settings {
    padding: 0px;
  }
  .home-title-en {
    font-size: 27px;
  }

  .home-title-sr, .home-title-es, .home-title-de {
    font-size: 25px;
  }

  .list-task-group {
    font-size: 14px;
  }

  .home-sublist-1, .list-group, .home-paragraph-3, .home-paragraph-2, .list-group-item, .home-title-3 {
    font-size: 13px;
  }

  .home-title-section-2 {
    font-size: 28px;
    padding-top: 2rem;
  }

  .home-ready-2 {
    font-size: 30px;
  }

  .home-title-2, .home-title-3, .home-paragraph-2, .home-paragraph-3 {
    margin-left: 5px;
  }

  .home-paragraph-3 {
    padding: 0;
  }
  .features-list {
    padding-left: 2rem;
  }

  .settings-3 {
    padding: 0 3px;
  }
  .MuiButton-label {
    font-size: 16px;
  }
  .what-test-paragraph, .home-p-section-3-1 {
    font-size: 15px;
  }
  .content-5 {
    padding: 40px 8px;
  }
  .MuiAccordionSummary-content p {
    font-size: 16px;
  }
  .faq-title {
    font-size: 18px;
  }
}

