.affiliate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 20vh; */
  text-align: left;
  margin: 40px 300px 80px 300px;
  /* color: unset; */
  background-color: #f6f8f9;
  border-radius:10px;
  padding: 30px;
  align-items: center;
}
.home-link {
  margin: auto;
}
.affiliate-title {
  margin-top: 50px;
  margin-bottom: 300px;
  font-size: 22px;
}

.affiliate-title-en {
  font-weight: 400;
  text-align: center;
}

.affiliate-fields {
  display: flex;
  flex-direction: column;
  /* justify-content: center */
  /* align-items: center; */
  margin-top: 10px;
}

.medium-promotion {
  text-align: left;
}

.medium-checkbox {
  margin: 7px;
}

.medium-promotion {
  margin: 14px 7px 0px 7px;
}

.affiliate-form {
  width: 50%;
}

.affilate-description {
  width: 70%;
}

.aff-sub-title {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 15px;
}

.aff-join {
  text-align: center;
}

.aff-li {
  margin-top: 12px;
}

@media (max-width: 1024px) {
  .affiliate-form {
    width: 60%;
  }
  .affilate-description {
    width: 70%;
  }
  .affiliate {
    margin: 40px 100px 80px 100px;
  }
}

@media (max-width: 800px) {
  .affiliate {
      margin: 40px 100px 80px 100px;
  }
  .affiliate-form {
    width: 80%;
  }
  .affilate-description {
    width: 90%;
  }
}

@media (max-width: 425px) {
  .affiliate {
      margin: 0px;
      border-radius: 0px;
  }
  .affiliate-title {
      font-size: 18px;
  }
  .affiliate-form {
    width: 100%;
  }
  .affilate-description {
    width: 100%;
  }
}

@media (max-width: 320px) {
  .affiliate-title {
      font-size: 16px;
  }
}