.question {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.singleQuestion {
  width: 95%;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 5px solid grey;
  padding: 20px;
  margin-top: 10px;
  border-radius: 3px;
}

.single-question-text {
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 30px;
  text-align: center;
  min-height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.options {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  justify-content: space-around;
  margin: 10px;
  min-height: 200px;
}

.singleOption {
  width: 100%;
  /* height: 50px; */
  padding: 15px 20px;
  margin: 20px;
  /* box-shadow: 0 0 2px black; */
  font-size: 20px;
  border-radius: 20px;
  cursor: pointer;
}

.singleOptionPersonality {
  width: 100%;
  padding: 15px 20px;
  font-size: 20px;
  border-radius: 20px;
  cursor: pointer;
}

.singleOptionPersonality-es {
  font-size: 17px!important;
  padding: 15px 10px;
}

.answer-wrap {
  width: 35%;
}

.answer-wrap-personality {
  width: 17%;
}

.single-question-image-container {
  min-height: 260px;
  display: flex;
  flex-direction: column;
}

.single-question-image {
  margin: auto;
}

.singleOption-image {
  width: 100%;
  /* height: 50px; */
  padding: 5px 20px;
  margin: 10px;
  /* box-shadow: 0 0 2px black; */
  font-size: 20px;
  border-radius: 20px;
  cursor: pointer;
}

.question-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 40px;
}
.question-number {
  font-size: 30px;
  margin-bottom: 0px;
}

.question-time {
  /* margin-left: auto; */
  font-size: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0px 40px;
}

.question-number-left {
  margin-left: auto;
}

.controls {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.select {
  background-color: rgb(7, 207, 0);
  color: white;
  /* box-shadow: 0 0 1px black; */
}

.wrong {
  background-color: rgb(233, 0, 0);
  color: white;
  /* box-shadow: 0 0 1px black; */
}
.time-box {
  width: 100px;
  display: inline-block;
}

.answer-wrap-english {
  width: 40%;
}
.answer-wrap-english-full {
  width: 70%;
}

.single-question-text-english {
  /* min-height: 150px; */
  flex: 0 0;
}
.answer-wrap-english-full .singleOption {
  margin: 10px 0px;
}
.answer-wrap-english .singleOption {
  margin: 10px 0px;
}

.single-question-text-wrap {
  width: 100%;
  background-color: #f6f8f9;
}

.previous-question {
  margin-top: 15px!important;
}

.option-selected {
  background-color: #c0d7fc;
}

.logical-animation {
  font-size: 40px;
  text-align: center;
  color: #F49F1C;
  font-weight: 600;
  margin-bottom: 0px;
  /* text-transform: uppercase; */
}


@media (max-width: 1440px) {
  .singleOptionPersonality-es {
    font-size: 15px!important;
    padding: 15px 10px;
  }
  .singleOptionPersonality-sr {
    padding: 15px 10px;
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .singleOptionPersonality {
    padding: 10px 5px;
    font-size: 15px;
  }
  .singleOptionPersonality-sr {
    padding: 10px 2px;
    font-size: 14px;
  }
  /* .singleOptionPersonality-es {
    font-size: 13px!important;
    padding: 15px 3px;
  } */
  .options-personality-es {
    flex-direction: column!important;
    min-height: 370px;
  }
  .answer-wrap-personality-es {
    width: 35%;
  }
  .question-number, .question-time {
    font-size: 30px;
  }
  .question {
    margin-top: 0px;
  }
}


@media (max-width: 614px) {
  .singleOption {
    width: 100%;
  }
  .logical-animation {
    font-size: 30px;
  }
}

@media (max-width: 425px) {
  .single-question-image {
    max-width: 335px;
  }
  .singleQuestion{
    border: 0;
    border-bottom: 2px solid #b3b3b3;
    border-top: 2px solid #e6e6e6;
    padding: 10px 0px 0px 0px;
  }
  .single-question-text-wrap {
    padding: 0px 10px;
  }
  .options {
    padding: 0px 10px 10px 10px;
  }
  .singleOption-image {
    padding: 5px 10px;
  }
  .single-question-text {
    margin-top: 60px;
    margin-bottom: 30px;
    font-size: 22px;
  }
  .image-answer {
    max-width: 80px;
  }
  .question-header {
    font-size: 12px;
  }
  .single-question-image-container {
    min-height: 220px;
  }
  .singleOption-image {
    margin: 10px 0 10px 0;
  }
  .singleOption {
    margin: 10px 0 10px 0;
  }
  .options-personality {
    flex-direction: column;
    min-height: 370px;
  }
  .options-english {
    flex-direction: column;
    min-height: 310px;
  }
  .answer-wrap-personality {
    width: 65%;
    margin: 5px;
  }
  .answer-wrap-english-full {
    width: 95%;
    margin: 5px;
  }
  .answer-wrap-english {
    width: 55%;
    margin: 5px;
  }
  .answer-wrap-english-full .singleOption {
    font-size: 18px;
    margin: 0;
  }
  .answer-wrap-english .singleOption {
    font-size: 18px;
    margin: 0;
  }
  .single-question-text-personality {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .single-question-text-english {
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  }
  .question-number, .question-time {
    font-size: 20px;
    margin-bottom: 0px;
  }
  .singleOptionPersonality {
    padding: 15px 15px;
    font-size: 19px;
  }
  .question {
    margin-top: 0px;
  }
  .question-header, .question-time {
    padding-left: 10px;
    padding-right: 10px;
  }
  .time-box {
    width: 50px;
  }
  .singleQuestionAnimation {
    min-height: 470px;
  }
}

@media (max-width: 410px) {
  .singleOptionPersonality {
    font-size: 17px!important;
  }
}

@media (max-width: 375px) {
  .single-question-image {
    max-width: 310px;
  }
  .image-answer {
    max-width: 70px;
  }
  .singleOption {
    font-size: 16px;
    padding: 15px 10px;
  }
  .single-question-text {
    margin-top: 40px;
    margin-bottom: 30px;
  }
  /* .question-header {
    margin-top: 5px;
  } */
  .options-personality {
    min-height: 350px;
  }
  .question-number, .question-time {
    font-size: 19px;
  }
  .singleOptionPersonality {
    font-size: 16px!important;
  }
}

@media (max-width: 355px) {
  .singleOptionPersonality {
    font-size: 15px!important;
  }
  .answer-wrap-personality {
    width: 70%;
  }
}

@media (max-width: 320px) {
  .single-question-image {
    max-width: 255px;
  }
  .image-answer {
    max-width: 60px;
  }
  .singleOption {
    font-size: 16px;
  }
  .singleOption-image {
    padding: 5px 5px;
  }
  .single-question-text {
    font-size: 18px;
  }
  .singleOption {
    font-size: 15px;
  }
  .question-number, .question-time {
    font-size: 18px;
  }
  .question-header {
    margin-top: 0px;
  }
  .singleQuestion {
    margin-top: 5px;
  }
  .options-personality {
    min-height: 335px;
  }
  .previous-question .MuiButton-label {
    font-size: 12px!important;
  }
}
