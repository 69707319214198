.quiz {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  font-family: "Poppins", sans-serif;
  margin: 0 270px 20px 200px;
}

.quiz-container {
  display: flex;
}

.subtitle {
  font-size: 25px;
  border: 1px solid black;
  /* box-shadow: 4px 4px 2px black; */
  padding: 5px 10px;
}

.quizInfo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  margin: 10px;
}

.progress-bar {
  margin-bottom: auto;
  padding-top: 60px;
  max-width: 185px;
  margin-right: 15px;
  text-align: center;
  width: 100%;
}

.progress-bar-personality {
  margin-top: 30px;
  padding-top: 10px;
  border-top: 2px solid #e6e6e6;
}

.riht-side-quiz {
  margin-bottom: auto;
  padding-top: 75px;
  max-width: 130px;
  margin-left: 15px;
  text-align: center;
  width: 100%;
}

.mobile-actions {
  display: none;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 10px;
}

.cancel-button .MuiButton-label{
  color: #E04113;
}

.cancel-button-wrap {
  margin-top: 10px;
}

.test-group-name {
  font-size: 25px;
  margin-bottom: 5px;
}

.test-group-name-personality {
  font-size: 23px;
}

.test-group-name-english {
  font-size: 21px;
  margin-bottom: 5px;
}

@media (max-width: 2000px) {
  .quiz {
    margin: 0 200px 0 200px;
  }
}

@media (max-width: 1700px) {
  .quiz {
    margin: 0 160px 0 160px;
  }
}

@media (max-width: 1440px) {
  .quiz {
    margin: 0 120px 0 80px;
  }
}

@media (max-width: 1250px) {
  .quiz {
    margin: 0 70px 0 70px;
  }
}

@media (max-width: 1024px) {
  .quiz {
    margin: 0 50px 0 50px;
    padding-top: 15px;
  }
  .progress-bar {
    max-width: 130px;
  }
}

@media (max-width: 950px) {
  .quiz {
    margin: 0 70px 0 70px;
    flex-direction: column-reverse
  }
  .quiz-container {
    flex-direction: column-reverse
  }
  .progress-bar {
    max-width: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
  .riht-side-quiz {
    display: none;
  }
  .mobile-actions {
    display: block;
  }
}

@media (max-width: 800px) {
  .quiz {
    margin: 0 70px 0 70px;
  }
}

@media (max-width: 425px) {
  .quiz {
    margin: 0 10px 30px 10px;
    padding-top: 5px;
  }
  .progress-bar {
    margin-top: 0px;
    max-width: 180px;
    padding-top: 45px;
  }
  .progress-bar-personality {
    max-width: 180px;
  }
}

@media (max-width: 375px) {
  .progress-bar {
    max-width: 160px;
  }
  .test-group-name {
    font-size: 20px;
  }
  .test-group-name-personality {
    font-size: 20px;
  }
  .test-group-name-english {
    font-size: 19px;
  }
  .single-question-text-personality, .single-question-text-english {
    font-size: 19px;
  }
  .singleOptionPersonality, .answer-wrap-english .singleOption, .answer-wrap-english-full .singleOption {
    font-size: 17px;
  }
  .single-question-text {
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .single-question-text-personality, .single-question-text-english {
    min-height: 150px;
  }
}

@media (max-width: 320px) {
  .single-question-text {
    min-height: 150px;
  }

  .single-question-text-personality, .single-question-text-english {
    font-size: 18px;
  }
  .singleOptionPersonality {
    font-size: 15px;
  }
  .answer-wrap-english-full .singleOption, .answer-wrap-english .singleOption {
    font-size: 15px;
  }
}
