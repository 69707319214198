.home-link {
  margin: auto;
}
.notFound-title {
  margin-top: 50px;
  margin-bottom: 300px;
  font-size: 22px;
  text-align: center;
}

.notFound-404 {
  text-align: center;
}

@media (max-width: 800px) {
  .notFound-content {
      margin: 40px 100px 80px 100px;
  }
}

@media (max-width: 425px) {
  .notFound-content {
      margin: 0px;
      border-radius: 0px;
  }
  .notFound-title {
      font-size: 18px;
  }
}